.hero-img{
  height: 500px;
}
.react-photo-gallery--gallery{
  img{
    border: 5px solid $white;
  }
}
.big-image{
 border: 16px solid rgba(255, 255, 255, 0.20)
}
.maximize-icon{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
}