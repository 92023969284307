@font-face {
  font-family: "Poppins Regular";
  src:  url("../../fonts/Poppins-Regular.ttf") format("opentype");
  src:  url("../../fonts/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold";
  src:  url("../../fonts/Poppins-SemiBold.ttf") format("opentype");
  src:  url("../../fonts/Poppins-SemiBold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold";
  src:  url("../../fonts/Poppins-Bold.ttf") format("opentype");
  src:  url("../../fonts/Poppins-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue Regular";
  src:  url("../../fonts/BebasNeue-Regular.ttf") format("opentype");
  src:  url("../../fonts/BebasNeue-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Regular";
  src:  url("../../fonts/Helvetica Neue LT Std 35 Thin.otf") format("opentype");
  src:  url("../../fonts/Helvetica Neue LT Std 35 Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINNextArabic Regular";
  src:  url("../../fonts/DINNextLTArabic-Regular-3.ttf") format("opentype");
  src:  url("../../fonts/DINNextLTArabic-Regular-3.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINNextArabic Bold";
  src:  url("../../fonts/DINNextLTArabic-Bold.ttf") format("opentype");
  src:  url("../../fonts/DINNextLTArabic-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINNextArabic Medium";
  src:  url("../../fonts/DINNextLTArabic-Medium.ttf") format("opentype");
  src:  url("../../fonts/DINNextLTArabic-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GESSTVBold Bold";
  src:  url("../../fonts/GESSTVBold-Bold.otf") format("opentype");
  src:  url("../../fonts/GESSTVBold-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

