.en{
  .btn{
    letter-spacing: 3.8px;
    outline: none !important;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 14px;
    padding: 0.7rem 0.3rem !important;
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.btn-primary{
      background: $primary-color;
      border: 1px solid $primary-color;

      &.text-light{
        color: $light-color;
        font-size: 13px;
        padding: 0.25rem 1.5rem !important;
        &.lt-sp-3{
          letter-spacing: 3.42px;
        }
        &:hover{
          background: $light-color;
          color: $primary-color !important;
        }
      }
      &.text-secondary-color{
        color: $secondary-color;
        &:hover{
          background: $secondary-color;
          color: $primary-color;
        }
      }

      &.inverse{
        background: $secondary-light-color !important;
        color: $primary-color !important;
        border: 1px solid $primary-color !important;
        opacity: 1 !important;
      }

    }

    &.btn-inverse{
      background: $light-color;
      color: $primary-color;
      border: 1px solid $primary-color;
    }

    &.btn-secondary{
      background: $secondary-color;
      color: $primary-color;
      border: 1px solid $secondary-color;
      padding: 1rem 0.3rem !important;
      &:hover{
        background: $primary-color;
        color: $secondary-color;
      }
    }
  }
}
.ar{
  .btn{
    outline: none !important;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 14px;
    padding: 0.7rem 0.3rem !important;
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.btn-primary{
      background: $primary-color;
      border: 1px solid $primary-color;

      &.text-light{
        color: $light-color;
        font-size: 13px;
        padding: 2px 1.95rem !important;
        padding-bottom: 5px !important;

        &.lt-sp-3{

        }
        &:hover{
          background: $light-color;
          color: $primary-color !important;
        }
      }
      &.text-secondary-color{
        color: $secondary-color;
        &:hover{
          background: $secondary-color;
          color: $primary-color;
        }
      }

      &.inverse{
        background: $secondary-light-color !important;
        color: $primary-color !important;
        border: 1px solid $primary-color !important;
        opacity: 1 !important;
      }

    }

    &.btn-inverse{
      background: $light-color;
      color: $primary-color;
      border: 1px solid $primary-color;
    }

    &.btn-secondary{
      background: $secondary-color;
      color: $primary-color;
      border: 1px solid $secondary-color;
      padding: 1rem 0.3rem !important;
      &:hover{
        background: $primary-color;
        color: $secondary-color;
      }
    }
  }
}
.btn-open-hrs{
  background: $secondary-light-color;
  color: #4B5D53 ;
  font-family: var(--font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-transform: uppercase;
  border: 1px solid #E4E2D9 !important;
  padding: 5px 20px;
}