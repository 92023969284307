nav {
  .nav-link {
    color: $primary-color;
    font-family: var(--font-regular);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 20px !important;
    margin-left: 20px !important;

    &:hover,
    &.active {
      font-weight: bolder;
    }
  }

  .navbar-brand {

    .logo {
      width: 200px;
      height: 50px;
      object-fit: contain;
    }

  }

  .navbar-nav-scroll {
    max-height: unset !important;
  }

  .navbar-nav .dropdown-menu {
    border-radius: 0;
    font-family: var(--font-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $primary-color;
    border: unset !important;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: $secondary-color;
    background-color: $primary-color;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: $secondary-color;
    background-color: $primary-color;
  }

  .dropdown-menu {
    --bs-dropdown-padding-x: 0rem;
  }

  .dropdown-menu::before {
    content: "";
    display: inline-block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -7px;
    left: 19%;
    margin-left: -6px;
  }

  .navbar-toggler {
    border: unset;
    color: $primary-color;
  }

  .navbar-toggler:focus {
    box-shadow: unset;
  }


}
//.navbar-expand-xl .navbar-nav .nav-link{
//  padding-right: 0 !important;
//  padding-left: 0 !important;
//}

.ar{
  .dropdown-item {
    text-align: start;
  }
}

.fs-e{
  font-family: $font-main-title !Important;
}
.ar{

  .ml-30{
    margin-left: 30px;
  }
}
.en{
  .mr-30{
    margin-right: 30px;
  }
}
.strip-alert{
  &.alert{
    --bs-alert-padding-y: 3px;
    --bs-alert-border-radius: 0px;
    --bs-alert-border: unset;
  }
}