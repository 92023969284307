.carousel.carousel-custom {
  // Indicators
  .carousel-indicators {
    align-items: center;
    position: static;
    z-index: auto;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      transform: none;
      opacity: 1;

      &.active {
        transform: none;
        opacity: 1;
        background-color: $primary-color;
      }
    }

    // Dots style
    &.carousel-indicators-dots {
      li {
        border-radius: 0;
        background-color: transparent;
        height: $carousel-custom-dots-indicator-active-size;
        width: $carousel-custom-dots-indicator-active-size;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:after {
          display: inline-block;
          content: ' ';
          @include border-radius(50%);
          transition: all $carousel-custom-indicator-transition-speed ease;
          background-color: $carousel-custom-indicator-default-bg-color;
          height: $carousel-custom-dots-indicator-default-size;
          width: $carousel-custom-dots-indicator-default-size;
        }

        &.active {
          background-color: transparent;

          &:after {
            transition: all $carousel-custom-indicator-transition-speed ease;
            height: $carousel-custom-dots-indicator-active-size;
            width: $carousel-custom-dots-indicator-active-size;
            background-color: $carousel-custom-indicator-active-bg-color;
          }
        }
      }
    }

    // Bullet style
    &.carousel-indicators-bullet {
      li {
        transition: all $carousel-custom-indicator-transition-speed ease;
        background-color: transparent;
        border-radius: $carousel-custom-bullet-indicator-default-size;
        height: $carousel-custom-bullet-indicator-default-size;
        width: $carousel-custom-bullet-indicator-default-size;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:after {
          display: inline-block;
          content: ' ';
          transition: all $carousel-custom-indicator-transition-speed ease;
          background-color: $carousel-custom-bullet-indicator-default-bg-color;
          border-radius: $carousel-custom-bullet-indicator-default-size;
          height: $carousel-custom-bullet-indicator-default-size;
          width: $carousel-custom-bullet-indicator-default-size;
        }

        &.active {
          transition: all $carousel-custom-indicator-transition-speed ease;
          background-color: transparent;
          height: $carousel-custom-bullet-indicator-default-size;
          width: $carousel-custom-bullet-indicator-active-width;

          &:after {
            transition: all $carousel-custom-indicator-transition-speed ease;
            height: $carousel-custom-bullet-indicator-default-size;
            width: $carousel-custom-bullet-indicator-active-width;
            background-color: $carousel-custom-bullet-indicator-active-bg-color;
          }
        }
      }
    }
  }


  // Stretch mode
  &.carousel-stretch {
    height: 100%;
    display: flex;
    flex-direction: column;

    .carousel-inner {
      flex-grow: 1;
    }

    .carousel-item {
      height: 100%;
    }

    .carousel-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
.carousel-indicators [data-bs-target] {
  background-color: $light-color;
  width: 9px;
  height: 8px;
  border-radius: 50%;
  opacity: 1 !important;


}
.carousel-indicators .active {
  background-color: $primary-color !important;
}
.carousel-caption {
  position: absolute;
  right: unset !important;
  top: 63%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  width: 60%;
}
.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.69;
  background: linear-gradient(357deg, #E9E4D6 43.46%, rgba(233, 228, 214, 0.00) 77.25%);
  &.o-100{
    background: linear-gradient(357deg, #E9E4D6 43.46%, rgba(233, 228, 214, 0.00) 100%);

  }
}
.home .carousel-img{
  height: 650px;
  object-fit: cover;
}


.carousel-control-prev-icon, .carousel-control-next-icon {
  display: none;
}
.architect .carousel-img{
  //height: 500px;
  //object-fit: contain;
}
.slick-slide{
  //margin-right: 5px;
  //margin-left: 5px;
}

.slick-slide:not(.slick-center) {
  transform: scale(0.9);
  transition: transform 0.3s ease;
}
.slick-dots li {
  width:2px !important;
}
.dark-carousel{
  .slick-prev:before, .slick-next:before{
    color: $primary-color !important;
  }
}
.left-arr{
  position: absolute;
  top: 45%;
  left: 2%;
  transform: translate(-50%, -50%);
}
.right-arr{
  position: absolute;
  top: 45%;
  right: 1%;
  transform: translate(-50%, -50%);
}
.slick-dots {
  bottom: -20px
}
.slick-dots li button:before {
  font-size: 10px
}
