.h-500px{
  height: 500px;
  object-fit: contain;
}
.h-400px{
  height: 400px;
  object-fit: contain;
}
.obj-cover{
  object-fit: cover !important;
}
.z-index-9999{
  z-index: 9999;
}