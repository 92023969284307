$breakpoint-1199: 1199px;
$breakpoint-991: 991px;
$breakpoint-767: 767px;
$breakpoint-575: 575px;

$font-main-title : "BebasNeue Regular" , serif;
$font-main-text-bold : "Poppins Bold" , serif;
$font-main-text-regular : "Poppins Regular" , serif;
$secondary-font: "Helvetica Regular" ,serif;

$font-main-title-ar :"GESSTVBold Bold" ,serif;
$font-main-text-bold-ar : "DINNextArabic Bold" , serif;
$font-main-text-regular-ar : "DINNextArabic Regular" , serif;
$secondary-font-ar: "DINNextArabic Regular" , serif;

$base-fontsize: 16;
$error-text-color: #dc3545;
$primary: #F3CE7F;
$text-color: #ffffff;
$subtitle-color: #DFD0B7;

$secondary-color: #CEBD9A;
$secondary-light-color: #E9E4D6;

$primary-color: #1F362C;
$primary-light-color: #28473A;

$light-color: #F8F8F2;

// Carousel
$carousel-custom-indicator-transition-speed: 0.3s !default;
$carousel-custom-indicator-default-bg-color: $light-color !default;
$carousel-custom-indicator-active-bg-color: $light-color !default;

$carousel-custom-dots-indicator-default-size: 9px !default;
$carousel-custom-dots-indicator-active-size: 13px !default;

$carousel-custom-bullet-indicator-default-size: 6px !default;
$carousel-custom-bullet-indicator-active-width: 16px !default;
$carousel-custom-bullet-indicator-default-bg-color: $light-color !default;
$carousel-custom-bullet-indicator-active-bg-color: $light-color !default;

$sizes: (
        '0'    : 0,
        '3'    : 3,
        '7'    : 7 ,
        '10'   : 10,
        '11'   : 11,
        '12'   : 12,
        '13'   : 13,
        '14'   : 14,
        '15'   : 15,
        '16'   : 16,
        '17'   : 17,
        '18'   : 18,
        '19'   : 19,
        '20'   : 20,
        '22'   : 22,
        '24'   : 24,
        '25'   : 25,
        '27'   : 27,
        '28'   : 28,
        '30'   : 30,
        '33'   : 33,
        '34'   : 34,
        '35'   : 35,
        '36'   : 36,
        '38'   : 38,
        '40'   : 40,
        '45'   : 45 ,
        '46'   : 46 ,
        '50'   : 50,
        '54'   : 54,
        '55'   : 55,
        '60'   : 60,
        '65'   : 65 ,
        '70'   : 70 ,
        '75'   : 75 ,
        '80'   : 80 ,
        '85'   : 85 ,
        '90'   : 90 ,
        '100'  : 100,
        '110'  : 110,
        '115'  : 115,
        '120'  : 120,
        '130'  : 130,
        '140'  : 140,
        '150'  : 150,
        '160'  : 160,
        '170'  : 170,
        '180'  : 180,
        '190'  : 190,
        '200'  : 200,
        '240'  : 240,
        '250'  : 250,
        '300'  : 300,
        '340'  : 340,
) !default;

$font-families : (
        'title-regular' : $font-main-title,
        'text-bold'  : $font-main-text-bold,
        'text-regular'  : $font-main-text-regular,


) !default;

@each $name, $value in $sizes {
    .w-#{$name}px{
        width: #{$value}px;
    }
    .mt-#{$name} {
        margin-top: #{$value}px;
    }
    .mb-#{$name} {
        margin-bottom: #{$value}px;
    }
    .mr-#{$name} {
        margin-right: #{$value}px;
    }
    .ml-#{$name} {
        margin-left: #{$value}px;
    }

    .m-#{$name} {
        margin: #{$value}px;
    }
    .p-#{$name} {
        padding: #{$value}px;
    }
    .pl-#{$name} {
        padding-left: #{$value}px;
    }
    .pr-#{$name} {
        padding-right: #{$value}px;
    }

}

.ar{
    direction: rtl;
    text-align: right;
    --text-align: right;
    --font-main-title : "GESSTVBold Bold" ,serif;
    --font-regular :"DINNextArabic Regular" , serif;
    --font-bold: "DINNextArabic Bold" , serif;
    --font-semibold:"DINNextArabic Bold" , serif;
    --font-secondary: "DINNextArabic Regular" , serif;
}


.en{
    direction: ltr;
    text-align: left;
    --text-align: left;
    --font-main-title : "BebasNeue Regular" , serif;
    --font-regular : "Poppins Regular" , serif;
    --font-bold:"Poppins Bold" , serif;
    --font-semibold:"Poppins SemiBold" , serif;
    --font-secondary:"Helvetica Regular" ,serif;

}