.days-week{
  ul{
    display: inline-flex;
    list-style: none;
    background: #F5F4F1;
    font-family: var(--font-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 175% */
    text-transform: uppercase;
    color: #4B5D53 ;
    padding-left: 0;
    width: 100%;
    li{
      padding: 4px 9px;
      &:hover{
        background: #D3C4A5 !important;
      }
    }
  }
}
.hour{

  color: #4B5D53 ;
  font-family: var(--font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;

  .bg-bdr{
    border: 1px solid #CEBD9A;
    background: #E9E4D6;
    padding: 3px 18px;
  }
}
.opening-hrs{
  position: absolute;
  top: 76%;
  width: 90%;
  display: none;
  z-index: 9999999;

  &.open {
    display: block;
  }
}
.active-day{
  background: #D3C4A5 !important;
}
.toggle-arrow{
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translate(-50%, -50%);
}