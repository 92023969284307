.main-h1-text{
  color: $primary-color;
  text-align: center;
  font-family: var(--font-main-title);
  font-size: 90px;
  font-style: normal;
  font-weight: 400;
  line-height: 77px; /* 92.308% */
}

.sub-text{
  color: $primary-color;
  font-family: var(--font-main-title);
  font-size: 30px;
  font-style: normal;
  line-height: 34px;
  text-transform: capitalize;
}
.fw-normal{
  font-weight: normal !important;
}

.en{
  .main-h2-text{
    color: $secondary-color;
    font-family: var(--font-main-title);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 1.6px;
    &.primary{
      color: $primary-color;
    }
  }
}
.ar{
  .main-h2-text{
    color: $secondary-color;
    font-family: var(--font-main-title);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    &.primary{
      color: $primary-color;
    }
  }
}

.en{
  .card-one{
    h6{
      color: $primary-color;
      font-family: var(--font-main-title);
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 137.5% */
      letter-spacing: 1.2px;
      text-transform: capitalize;
    }
    p{
      color: #4B5D53 !important;
      font-family: var(--font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 166.667% */
    }
  }
  .main-p-text{
    color: $primary-color;
    text-align: center;
    font-family: var(--font-regular);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    &.signature{
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.2px;
      text-transform: uppercase;
    }
    &.opc{
      color: #4B5D53 !important;
    }
  }
}
.ar{
  .card-one{
    h6{
      color: $primary-color;
      font-family: var(--font-main-title);
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 137.5% */
      text-transform: capitalize;
    }
    p{
      color: #4B5D53 !important;
      font-family: var(--font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 166.667% */
    }
  }
  .main-p-text{
    color: $primary-color;
    text-align: center;
    font-family: var(--font-regular);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    &.signature{
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    &.opc{
      color: #4B5D53 !important;
    }
  }
}

.title-1{
  color:$primary-color;
  font-family: var(--font-main-title);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  text-transform: capitalize;
  &.ss{
    font-size: 28px;
    line-height: 32px;
  }
}

.title-1-secondary{
  color:$secondary-color;
  font-family: var(--font-main-title);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  text-transform: capitalize;
}
.sm-primary{
  color: $primary-color;
  font-family:  var(--font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.info-sm-primary{
  color: $primary-color;
  font-family:  var(--font-regular);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.en{
  .text-secondary-d{
    color: $secondary-color;
    font-family: var(--font-main-title);
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 180% */
    letter-spacing: 1.2px;
    text-transform: capitalize;
    &.sm-d{
      font-size: 23px;
      line-height: 27px;
    }
    &.ss{
      font-size: 18px;
      line-height: 24px;
    }
  }

}
.ar{
  .text-secondary-d{
    color: $secondary-color;
    font-family: var(--font-main-title);
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 180% */
    text-transform: capitalize;
    &.sm-d{
      font-size: 23px;
      line-height: 27px;
    }
  }

}
.main-text {
  color: #4B5D53 !important;
  font-family: var(--font-regular);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  p, span, a, div{
    color: #4B5D53 !important;
    font-family: var(--font-regular) !important;
    font-size: 13px !important;
    line-height: 20px !important;
  }
  img{
    width: 100% !important;
  }
  h1,h2,h3,h4,h5{
    font-family: var( --font-bold) !important;
  }

  &.secondary{
    color: $secondary-color !important;
  }

  &.light {
    color: #A0AAA3 !important;
  }

  .opc {
    color: #4B5D53 !important;
  }

  .g-l {
    font-size: 17px;
    line-height: 26px;

    &.lh-b {
      line-height: 28px;
    }
  }
}
.text-2-p{
  color: $primary-color;
  font-family: var( --font-bold);
  font-size: 15px;
  line-height: 20px; /* 181.818% */
}
.f-bold{
  font-family: var( --font-bold);
}

.en{
  .rd-sm{
    color: $primary-color;
    font-family: var(--font-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    &:hover{
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}
.ar{
  .rd-sm{
    color: $primary-color;
    font-family: var(--font-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    &:hover{
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.text-secondary-fs{
  color: $secondary-color;
  font-family: var( --font-secondary);
  font-size: 18px;
  font-style: normal;
  font-weight:600;
  line-height: normal;
  text-transform: uppercase;
}
.centered-text{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-title{
  font-size: 70px;
  line-height: 69px;
  font-family: var(--font-main-title);
  text-align: center;
  color: $primary-color;
}
.text-secondary-lg{
  color: $secondary-color;
  font-family: var(--font-main-title);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 163.636% */
  letter-spacing: 1.32px;
  text-transform: capitalize;
  &.lh-lg{
    line-height: 30px !important;
  }

}
.counter-type {
  color: #48594E;
  text-align: center;
  font-family: var(--font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.timer {
  color: #FFF;
  text-align: center;
  font-family: var( --font-bold);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.separator-counter{
  position: absolute;
  right: -8%;
  top: 46%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color:  $secondary-color;
}

.hidden-separator {
  display: none;
}
.border-secondary-all{
  border: 1px solid $secondary-color;
}

.subtitle{
  color: $primary-color;
  font-family: var(--font-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.8;

  .opc-1{
    opacity: 1 !important;
  }
}
.lh-30{
  line-height: 30px;
}
.close-modal-x{
  position: absolute;
  top: -12px;
  right: -8px;
  font-size: 40px;
  color: $light-color;
}
.highlights{
  &.modal {
    --bs-modal-bg:transparent;
    --bs-modal-border-color: unset;
  }
}
.event-image-popup{
  &.modal {
    --bs-modal-bg:#E9E4D6;
    --bs-modal-border-color: unset;
    --bs-modal-border-radius: 0
  }
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}
.cursor-pointer{
  cursor: pointer;
}

.day-c{
  color: #4B5D53 ;
  font-family: var(--font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 175% */
  text-transform: uppercase;
}
.lh-34{
  line-height: 34px;
}

.fv-plugins-message-container{
  color:#dc3545;
  font-size: 12px;
  font-weight: bold;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.7 !important;
}

.en{
  .calendar-date{
    color: $primary-color;
    text-align: center;
    font-family:  var( --font-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.154px;
  }
  .sections-title{
    padding: 10px;
    color:  #4F4F4F;
    text-align: center;
    font-family: var( --font-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.154px;
    text-transform: uppercase;
    &.text-normal{
      text-transform: none;
    }
    &.active{
      background: $primary-color;
      color: $secondary-color;
      text-align: center;
      font-family:var( --font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.154px;
      text-transform: uppercase;
    }
  }
}

.ar{
  .calendar-date{
    color: $primary-color;
    text-align: center;
    font-family: var( --font-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sections-title{
    padding: 10px;
    color:  #4F4F4F;
    text-align: center;
    font-family: var( --font-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    &.active{
      background: $primary-color;
      color: $secondary-color;
      text-align: center;
      font-family:var( --font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

.sections-list{
  list-style: none;
  padding: 0;
  li{
    margin-right: 10px;
    margin-left:10px
  }
}
.en{
  .speaker-name{
    color: $primary-color;
    font-family: var( --font-main-title);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 113.793% */
    letter-spacing: 0.87px;
    text-transform: capitalize;
  }
}
.ar{
  .speaker-name{
    color: $primary-color;
    font-family: var( --font-main-title);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 113.793% */
    text-transform: capitalize;
  }
}
.grey-color{
  color: #A6A6A6
}

.text-primary-color{
  color: $primary-color !important;
}
.f-semi-bold{
  font-family: var( --font-semibold);
}
.f-regular{
  font-family: var( --font-regular);
}
.text-no-decoration{
  text-decoration: none !important;
}