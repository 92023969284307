.bg-secondary-color {
  background: $secondary-light-color;
}

.bg-primary-color {
  background: $primary-color;
}

.bg-light-color {
  background: $light-color;
}
.bg-lighter{
  background: #F9F9F9;
  border: 1px solid #E3D8B8;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
}
.bg-split-primary{
  background: linear-gradient(to bottom, $light-color 90%, $primary-color 10%);

}
.bg-split-secondary{
  background: linear-gradient(to bottom, $light-color 90%, $secondary-light-color 10%);
}
.bg-days{
  background: #F5F4F1;
}