.form-control{
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}
.en{
  .main-input{
    letter-spacing: 2.96px;
  }
}
.main-input{
  background: #F0EEE4;
  color: #A6A6A6;
  font-family: var(--font-regular);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-transform: uppercase;
}
.main-input::placeholder {
  color: #A6A6A6;
}
.css-13cymwt-control {
  background-color: #F0EEE4 !important;
  border-radius: 0 !important;
  border: 1px solid #E4E2D9 !important;
}
.invalid-select{
  border:1px solid var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.valid-select{
  border: 1px solid var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.css-1u9des2-indicatorSeparator{
  display: none;
}
.css-1jqq78o-placeholder{
  color: #A6A6A6 !important;
}
.main-label{
  color:$primary-color;
  font-family: var(--font-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 181.818% */
}

.ar{
  .newsletter-input-group{

    position: relative;
    i{
      color: #A6A6A6;
      position: absolute;
      top: 52%;
      right: 5%;
      transform: translate(-50%, -50%);
    }

    .newsletter-input{
      font-family: var(--font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 211.111% */
      text-transform: uppercase;
      height: 65px;
      text-indent: 28px;
    }
  }
}
.en{
  .newsletter-input-group{

    position: relative;
    i{
      color: #A6A6A6;
      position: absolute;
      top: 50%;
      left: 6%;
      transform: translate(-50%, -50%);
    }

    .newsletter-input{
      font-family: var(--font-regular);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 211.111% */
      letter-spacing: 3.96px;
      text-transform: uppercase;
      height: 65px;
      text-indent: 25px;
    }
  }
}

.newsletter-input::placeholder {
  color: #A6A6A6;
}
.ar{
  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-right: 12px;
    padding-left: calc(1.5em + 0.75rem);
  }
  .was-validated .form-control:valid, .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-right:12px;
    padding-left: calc(1.5em + 0.75rem);
  }

  .invalid-select {
    padding-right:0;
    padding-left: calc(1.5em + 0.75rem);
    background-position: left calc(0.375em + 0.1875rem) center;
  }

}




.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #1F362C; /* green color */
  border-radius: 0px;
  background-color: transparent; /* transparent background */
  cursor: pointer;
  vertical-align: middle;
  margin-right: 10px; /* spacing between checkbox and text */
  position: relative;
}

.custom-checkbox:checked + .checkmark {
  background-color: #1F362C; /* green background when checked */
  border-color: #1F362C; /* green border when checked */
}

.custom-checkbox::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 15px;
  border: solid #1F362C;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0; /* Hide the tick by default */
}

.custom-checkbox:checked::before {
  opacity: 1; /* Show the tick when checked */
}
