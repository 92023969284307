footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 590px; /* Set the fixed height of the footer here */
  $elements_max_width: 270px;
  //@media (max-width: $breakpoint-767) {
  //  height: auto;
  //  position: static;
  //}
  @media (max-width: $breakpoint-1199) {
    height: auto;
    position: static;
  }
  //@media (max-width: $breakpoint-991) {
  //  height:820px;
  //}

  ul {
    @include reset-padding-margin;

    li {
      margin-top:10px;
      color: $light-color;
      @include inline-li;
      font-size: 13px;
      text-transform: uppercase;
      font-family: var(--font-regular);
      @include transition-all(0.3s);

      &:hover {
        color: $secondary-color;
      }
      @media (max-width: $breakpoint-767) {
        display: block;
      }
    }
  }

  .logo-container {
    img {
      max-width: 200px;
    }
  }

  .subscribe-text{
    color: #F8F8F2;
    font-family:var(--font-main-title);
    font-size: 75px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    span{
      color: $secondary-color;
    }
  }
  .social-links {
    display: inline-flex;


    li {
      width: 30px;
      margin-right: 10px;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px; /* Adjust the size of the circle */
      border-radius: 50%;
      border: 1px solid $secondary-light-color;
      color: $secondary-light-color;
      //box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      &:hover{
        color: $secondary-color;
      }
    }

  }
  .subscribe-p{
    color: #FFF;
    font-family: var(--font-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    opacity: 0.8;
  }


.contact-title{
  color: $light-color;
  font-family: var( --font-bold);
  font-size: 13px;
  font-style: normal;
  line-height: 17px;
  text-transform: uppercase;
}
  .contact-description{
    color: $light-color;
    font-family: var(--font-regular);
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px; /* 142.857% */
    text-transform: capitalize;
    i{
      color: $secondary-color;
    }
  }
  .copy-right{
    color: $light-color;
    text-align: center;
    font-family: var(--font-regular);
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px; /* 166.667% */
    text-transform: capitalize;
    opacity: 0.7;
  }

  .dropdown-menu{
    border-radius: 0;
    font-family: var(--font-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $primary-color;
    border: unset !important;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: $secondary-color;
    background-color: $primary-color;
  }
  .dropdown-item.active, .dropdown-item:active {
    color: $secondary-color;
    background-color: $primary-color;
  }
  .dropdown-menu {
    --bs-dropdown-padding-x: 0.5rem;
  }
  //.dropdown-menu::before {
  //  content: "";
  //  display: inline-block;
  //  border-left: 10px solid transparent;
  //  border-right: 10px solid transparent;
  //  border-bottom: 10px solid #fff;
  //  position: absolute;
  //  top: -7px;
  //  left: 19%;
  //  margin-left: -6px;
  //}
}

.en {
  footer ul li {
    margin-right: 40px;
  }
  footer .social-links li {
    //margin-left: 10px !important;
    margin-right: 10px;
  }
}
.ar {
  footer ul li {
    margin-left: 40px;
  }
}
.ar {
  footer .social-links{
    padding-right: 0;
  }
  footer .social-links li {
    margin-left: 10px !important;
    margin-right: 0;

  }
  footer .subscribe-text {
    font-size: 60px;
  }
}