input, textarea {
    outline: none !important;
}

* {
    outline: none !important;
}

html {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    //background: $white;

}

body {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 590px;
    background: $light-color;
    @media (max-width: $breakpoint-1199) {
        margin-bottom:0;
    }

}

body, html {
    padding: 0;
}

.h-185px{
    height: 185px;
}




h1, h2, h3 {

}

a {
    color: inherit;
    text-decoration: none;
}

a:hover, a:focus {
    color: inherit;
}

textarea {
    resize: none;
}

.w-100 {
    width: 100%;
}

.text-center-991 {
    @media (max-width: $breakpoint-991) {
        text-align: center;
    }
}

.hide-767 {
    @media (max-width: $breakpoint-767) {
        display: none;
    }
}

.show-767 {
    display: none;

    @media (max-width: $breakpoint-767) {
        display: inline-block;
    }
}

.row-eq-height {
    @media (min-width: 992px) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}






.h-120px{
    height: 120px;
}
.h-150px{
    height: 150px;
}
.h-180px{
    height: 180px;
}
.object-cover{
    object-fit: cover;
  }
.object-contain{
    object-fit: contain;
}
.h-300px{
    height: 300px;
}
.h-250px{
    height: 250px;
}


.h-400px{
    height: 400px;
}

.h-500px{
    height: 500px;
}
.en{
    .lt-sp-md{
        letter-spacing: 1.2px;
    }
}

.w-h-70{
    width: 84px;
    height: 73px;
}



.h-180px{
    height: 180px;
}
.bdr-white{
    border: 6px solid #fff;
}
.card{
    background: transparent;
    background-color: transparent;
}
.card {
    border-radius: 0;
    border: transparent;
}
.card.card-custom.card-stretch.gutter-b {
    height: calc(100% - 30px);
}

.card.card-custom.card-stretch {
    display: flex;
    align-items: stretch !important;
    flex-direction: column;
    height: 100%;
}

.card.card-custom {
    //box-shadow: 0px 0px 30px #1D7FAB16;
}
.example.example-compact {
    position: relative;
}
.gutter-b {
    margin-bottom: 30px;
}
.bdr-15{
   border-radius: 15px;
}
.minW{
    min-width: 91px;
    text-align: center;
}

.p-50{
    padding: 50px
}
.bdr-20 {
    border-radius: 20px
}
.right-sign{
    position: absolute;
    top: 27%;
    right: 3%

}

.left-sign{
    position: absolute;
    top: 27%;
    left: 3%
}
.ml-20{
    margin-left: 20px
}
.mr-20{
    margin-right: 20px;
}
.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 88.25%; /* 16:9 Aspect Ratio (9 / 16 * 100) */
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.h-250-cover{
    height: 250px;
    object-fit: cover;
}
.bdr-img-white{
    border: 8px solid #FFF;

}


