
@media (min-width: 1200px) {

  //.slick-list{
  //  height: 400px;
  //}
  .pl-lg-70{
    padding-left: 70px;
  }
  .pr-lg-70{
    padding-right: 70px;
  }

}
@media (max-width: 1100px) {
  nav .dropdown-menu::before{
    display: none !important;
  }
}
@media (max-width: 992px) {
  .h-250-cover{
    height: auto;
  }


  .ar{

    .ml-30{
      margin-left:unset !important;
    }
  }
  .en{
    .mr-30{
      margin-right: unset !important;
    }
  }
  nav .dropdown-menu::before{
    display: none !important;
  }

  .h-500px{
    height: auto;
    object-fit: contain;
  }
  .h-400px{
    height: auto !important;
    object-fit: contain !important;
  }
  .close-modal-x {
    right: 4px;
    font-size: 35px;
  }
  footer .subscribe-p {
    line-height: 14px;
  }
  .title-1{
    font-size: 31px;
  }
  .carousel-caption{
    width: 85%;
  }
  .main-h2-text {
    font-size: 40px !important;
  }
    .bg-split-primary{
    background: linear-gradient(to bottom, $light-color 100%, $primary-color 0%);

  }
  .bg-split-secondary{
    background: linear-gradient(to bottom, $light-color 100%, $secondary-light-color 0%);
  }
  html{
    background: $primary-color;
  }
  .hero-title {
    font-size: 50px;
    line-height: 48px;
  }
  footer .subscribe-text{
    font-size: 50px ;
    line-height: 44px;
  }
  .hero-img{
    height: 400px;
  }
 .pl-md-30{
   padding-left: 30px;
 }
  .pr-md-30{
    padding-right: 30px;
  }
  .home .carousel-img{
    height: 450px;
    object-fit: cover;
  }
  .card-one{
    h6{
      font-size: 31px;
      line-height: 50px;
    }

  }
  .main-h1-text{
    font-size: 55px;
    line-height: 48px;
  }

  .main-p-text{
    font-size: 14px;
    line-height: 20px;
  }
  .card-one{
    p{
      font-size: 14px;
    }
  }
}

@media (max-width: $breakpoint-767) {

  body{
    margin-bottom: 0;
  }

}

@media (max-width: 992px){
  .dropdown-menu{
    --bs-dropdown-min-width: 100%;
  }
  .ar{
    footer .subscribe-text {
      font-size: 39px;
      line-height: 44px;
    }

   .newsletter-input-group i {
     right: 3%;
    }
  }
}